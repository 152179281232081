import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hello-im-dustin-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#hello-im-dustin-",
        "aria-label": "hello im dustin  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hello, I'm Dustin. 👋`}</h1>
    <p>{`Here is a quick intro - all about me.`}</p>
    <h2 {...{
      "id": "early-years",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#early-years",
        "aria-label": "early years permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Early Years`}</h2>
    <p>{`I was raised in rural eastern Utah (Roosevelt and Ballard). My parents were
divorced when I was in 6th grade. I lived in Salt Lake City and went to Jr. High
there (8th & 9th grade). I moved back to Roosevelt for High School.`}</p>
    <p>{`Growing up, I was very much into sports. My dad coached football, wrestling, and
baseball. My older brother and I often got to with him and the team on long bus
trips.`}</p>
    <p>{`In high school, I played football 🏈, basketball 🏀, and baseball ⚾️. I ended
my senior year by breaking my arm while pitching in the state tournament,
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=EzLtowZNrmE"
      }}>{`Dave Dravecky`}</a>{` style. 😬💔`}</p>
    <p>{`I recovered and shortly after this I served a 2-year mission for
`}<a parentName="p" {...{
        "href": "https://churchofjesuschrist.org"
      }}>{`The Church of Jesus Christ of Latter-day Saints`}</a>{`
in `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/South_Africa"
      }}>{`South Africa`}</a>{`. 🇿🇦`}</p>
    <h2 {...{
      "id": "family--career",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#family--career",
        "aria-label": "family  career permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Family & Career`}</h2>
    <p>{`When I returned home, I went to work for
`}<a parentName="p" {...{
        "href": "https://selecthealth.org/"
      }}>{`IHC Health Plans`}</a>{`. They generously paid 80% of my
tuition at `}<a parentName="p" {...{
        "href": "http://www.slcc.edu/"
      }}>{`Salt Lake Community College`}</a>{`.`}</p>
    <p>{`There, I met my beautiful wife Rachel. I continued to work and got to school. I
later moved my career to
`}<a parentName="p" {...{
        "href": "https://www.3m.com/3M/en_US/health-information-systems-us/?utm_term=hcbg-his-na-en_us-ba-hisbrand-cpc-google-na-learn-v2-ne20-na&s_kwcid=AL!6248!3!447690737891!e!!g!!3m%20his"
      }}>{`3M Health Information Systems`}</a>{`
and my schooling to `}<a parentName="p" {...{
        "href": "https://www.weber.edu/"
      }}>{`Weber State University`}</a>{` where I got
my degree in Computer Information Systems. Also, thanks to 3M for their 100%
tuition reimbursement program! 💯`}</p>
    <p>{`I've been fortunate to work for some great companies as you can see from my
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/dustindavis/"
      }}>{`LinkedIn`}</a>{` profile.`}</p>
    <p>{`I currently live in northern Utah with my wife 👧🏻 and 5 kids 🙎🏼‍♀️🙍🏼‍♂️🙍🏻‍♂️🙍🏼‍♂️🙍🏻‍♂️ and our
dog 🐶. Now my sport of choice is pickleball!`}</p>
    <h2 {...{
      "id": "why-blog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-blog",
        "aria-label": "why blog permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why Blog?`}</h2>
    <p>{`I've been blogging for many years. More than anything, I blog for myself. I
can't tell you how often I have gone back to my own blog to remember how I did
something in the past. It is also fun to have co-workers tell me they found an
answer to a question on my blog. They say, "I should have just come asked you."
My response is, "I would have just sent you to my blog."`}</p>
    <p>{`I hope you find something useful for your work or home life here. Feel free to
leave a comment or reach out to me on
`}<a parentName="p" {...{
        "href": "https://twitter.com/dustindavis/"
      }}>{`Twitter`}</a>{` and say hello.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      